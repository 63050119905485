import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'

import { baseButtonStyling } from './Button'

type Props = LoadingButtonProps & {
  mode?: 'back' | 'forward'
}

function LoadingButton({ mode, ...props }: Props) {
  return (
    <MuiLoadingButton
      disableElevation
      variant="contained"
      startIcon={mode === 'back' ? <ArrowBack /> : null}
      endIcon={mode === 'forward' ? <ArrowForward /> : null}
      {...props}
      sx={{
        ...baseButtonStyling,
        ...props.sx,
      }}
    >
      {props.children}
    </MuiLoadingButton>
  )
}

export default LoadingButton
