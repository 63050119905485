import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import LoadingButton from 'components/mui/LoadingButton'
import TextInput from 'components/mui/TextInput'

function Contact() {
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useRef()

  const SERVICE_ID = 'service_7hitbhh'
  const TEMPLATE_ID = 'template_zz2j3do'
  const PUBLIC_KEY = 'JyFy3F0veizFm3zBd'

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSubmitting(true)

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, form?.current || '', PUBLIC_KEY)
      .then((result) => {
        console.log(result.text)
        setSubmitted(true)
        setIsSubmitting(false)
      })
      .catch((error) => {
        console.log(error.text)
        setIsSubmitting(false)
      })
  }

  return (
    <Box component="form" ref={form} onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid
        container
        id="contact"
        justifyContent="center"
        textAlign="center"
        rowGap={4}
        sx={{ backgroundColor: 'secondary.light' }}
      >
        {submitted ? (
          <Grid item container justifyContent="center" m={4}>
            <Typography color="#fff">Thanks for contacting us!</Typography>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            sm={6}
            p={2}
            rowGap={2}
            color={'#fff'}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                color="primary"
                textTransform="uppercase"
                sx={{
                  fontFamily:
                    'Saira Extra Condensed, Helvetica Neue, Helvetica, Arial, sans-serif',
                  fontSize: '2.5rem',
                  fontWeight: 400,
                  letterSpacing: '7.3px',
                  textTransform: 'uppercase',
                }}
              >
                Contact Bruce
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput
                required
                id="firstName"
                name="firstname"
                label="First Name"
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput
                required
                id="lastName"
                name="lastname"
                label="Last Name"
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput
                id="phone"
                name="phone"
                label="Phone"
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextInput
                id="message"
                name="message"
                label="Message"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} md={8} justifyContent="start">
              <LoadingButton
                type="submit"
                color="primary"
                loading={isSubmitting}
                sx={{
                  '&.MuiLoadingButton-loading': {
                    backgroundColor: 'primary.main',
                  },
                  '&.MuiLoadingButton-loadingIndicator': { color: '#fff' },
                }}
              >
                Send
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Contact
