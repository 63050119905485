import { unstable_HistoryRouter as Router } from 'react-router-dom'

import './index.css'
import { ThemeProvider } from '@mui/material/styles'

import Dashboard from 'views/Dashboard'
import history from 'common/history'
import theme from 'styles/theme'

function App() {
  return (
    <Router history={history as any}>
      <ThemeProvider theme={theme}>
        <Dashboard />
      </ThemeProvider>
    </Router>
  )
}

export default App
