import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#25A6BD',
    },
    secondary: {
      main: '#black',
      light: '#140505',
    },
    error: {
      main: '#red',
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        color: 'primary',

        inputProps: {
          style: {
            color: '#fff', // This sets the text field font color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          letterSpacing: '0.009rem',
          fontSize: '0.813rem',
          fontWeight: 600,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily:
            'Open Sans, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
          '&[disabled]': {
            color: 'grey',
            pointerEvents: 'none',
            textDecorationColor: 'grey',
          },
        },
      },
    },
  },
  typography: {
    fontFamily:
      'Open Sans, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
    // Display Title 1
    h1: {
      fontFamily:
        'Saira Extra Condensed, Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '3.125rem',
      lineHeight: '4.5rem',
      fontWeight: 700,
      '@media (max-width:900px)': {
        fontSize: '2.125rem',
        lineHeight: '3rem',
      },
    },
    // Display Title 2
    h2: {
      fontSize: '3.125rem',
      lineHeight: '4.5rem',
      fontWeight: 700,
      '@media (max-width:900px)': {
        fontSize: '2.125rem',
        lineHeight: '3rem',
      },
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    // Display Title 3
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2.188rem',
      fontWeight: 600,
      '@media (max-width:600px)': {
        fontSize: '1.275rem',
        lineHeight: '1.875rem',
      },
    },
    // Card Title
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    // Heading
    h5: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    // Field title
    h6: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    // Subtitle
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 400,
    },
    // Link small
    subtitle2: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    // Body regular
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.875rem',
      fontWeight: 400,
      // Prevent auto zooming on form inputs for touch screen users
      '@media (hover: none)': {
        fontSize: 'max(1rem, 16px)',
      },
    },
    // Body bold
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 600,
    },
    button: {
      textTransform: 'inherit',
      fontWeight: 600,
    },
  },
})

export default theme
