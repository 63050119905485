import { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ResponsiveBarMenuItem } from 'common/types'
import Link from 'components/mui/Link'
import logoPath from 'assets/bruce-bowser-logo.webp'
import theme from 'styles/theme'
import styles from './responsiveHeaderBar.module.css'

type Props = {
  children: JSX.Element
  showNavItems?: boolean
}

const HeaderBar = ({ children, showNavItems = true }: Props) => {
  const { hash: currentHash } = useLocation()

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const smallScreenBreakpoint = 'md'
  const isSmallScreenBreakpoint = useMediaQuery(
    theme.breakpoints.down(smallScreenBreakpoint)
  )

  const menuItems = useMemo(
    () => [
      {
        path: '#biography',
        text: 'Biography',
      },
      {
        path: '#contact',
        text: 'Contact',
      },
    ],
    []
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElNav(event.currentTarget)

  const handleCloseNavMenu: () => void = () => setAnchorElNav(null)

  const FullScreenNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <Link
      href={path}
      style={{ margin: '0 0.8rem' }}
      className={classNames([
        { [styles.navLinkActive]: currentHash === path },
        styles.navLink,
      ])}
      sx={{ textDecoration: 'none', color: 'secondary.light' }}
    >
      {text}
    </Link>
  )

  const MobileNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <MenuItem onClick={handleCloseNavMenu} sx={{ p: 0 }}>
      <Link
        href={path}
        className={classNames([
          { [styles.mobileNavLinkActive]: currentHash === path },
          styles.mobileNavLink,
        ])}
        sx={{ textDecoration: 'none', color: 'secondary.light' }}
      >
        {text}
      </Link>
    </MenuItem>
  )

  return (
    <div className={styles.root}>
      <AppBar
        position="static"
        sx={{
          '&.MuiAppBar-colorPrimary': {
            backgroundColor: '#fff',
          },
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container alignItems="center">
              <Grid item width="auto">
                <img
                  src={logoPath}
                  alt="Bruce Bowser signature logo"
                  width={isSmallScreenBreakpoint ? '200' : '380'}
                />
              </Grid>
              {showNavItems && (
                <>
                  <Grid item flex={1}>
                    <>
                      {isSmallScreenBreakpoint ? (
                        <Box
                          sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                          >
                            <MenuIcon />
                          </IconButton>
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                              display: {
                                xs: 'block',
                                [smallScreenBreakpoint]: 'none',
                              },
                            }}
                          >
                            {menuItems.map((menuItem) => (
                              <MobileNavLink
                                key={menuItem.text}
                                path={menuItem.path}
                                text={menuItem.text}
                              />
                            ))}
                          </Menu>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            flexGrow: 1,
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {menuItems.map((menuItem) => (
                            <FullScreenNavLink
                              key={menuItem.text}
                              path={menuItem.path}
                              text={menuItem.text}
                            />
                          ))}
                        </Box>
                      )}
                    </>
                  </Grid>
                </>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </div>
  )
}
export default HeaderBar
