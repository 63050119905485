import Grid from '@mui/material/Grid'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

type Props = TextFieldProps & {
  label?: string
}

function TextInput({ label, ...props }: Props) {
  return (
    <Grid container>
      {label && (
        <Typography mb={1}>
          {label}
          {props.required && <span style={{ color: '#CF0C0C' }}> *</span>}
        </Typography>
      )}
      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#cacaca',
            },
            '&:hover fieldset': {
              borderColor: '#cacaca',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
        {...props}
      />
    </Grid>
  )
}

export default TextInput
