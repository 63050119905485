import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import bookCover from 'assets/focus-effect-banner.webp'
import Link from 'components/mui/Link'

function Hero() {
  return (
    <Grid
      container
      bgcolor="primary.main"
      rowGap={2}
      columnSpacing={2}
      px={2}
      py={4}
    >
      <Grid item container xs={12} lg={6}>
        <Box mx={1}>
          <img
            src={bookCover}
            alt="Focus Effect book cover"
            width="750"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Grid>
      <Grid item container xs={12} lg={6} rowGap={2} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h1"
            color="#fff"
            sx={{
              letterSpacing: '7.3px',
              textTransform: 'none',
            }}
          >
            The art of unplugging from technology and plugging into reality.
          </Typography>
        </Grid>
        <Grid item xs={12} color="#fff">
          <Typography>Published by Lioncrest</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            to="https://www.amazon.ca/Focus-Effect-Change-
            Your-Work-ebook/dp/B07CBP8CN6"
            target="_blank"
            sx={{
              backgroundColor: '#000',
              color: '#fff',
              marginTop: '5px',
              padding: '17px 40px',
              textDecoration: 'none',
            }}
          >
            Order Now
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Hero
