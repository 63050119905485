import Biography from 'components/Biography'
import Contact from 'components/Contact'
import Footer from 'components/Footer'
import Hero from 'components/Hero'
import ResponsiveHeaderBar from 'components/ResponsiveHeaderBar/ResponsiveHeaderBar'

function Dashboard() {
  return (
    <ResponsiveHeaderBar>
      <>
        <Hero />
        <Biography />
        <Contact />
        <Footer />
      </>
    </ResponsiveHeaderBar>
  )
}

export default Dashboard
