import { Link as RouterLink } from 'react-router-dom'

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

export type LinkProps = MuiLinkProps & {
  disabled?: boolean
  to?: string
}

function Link({
  disabled = false,
  to,
  color = 'primary',
  variant = 'inherit',
  ...props
}: LinkProps) {
  const config: any = { ...props, to, disabled }

  if (props.onClick) {
    config.component = 'button'
  }

  if (to) {
    config.component = RouterLink
  }

  return (
    <MuiLink
      color={color}
      variant={variant}
      sx={{ textDecorationColor: color }}
      {...config}
    >
      {props.children}
    </MuiLink>
  )
}

export default Link
