import Box from '@mui/material/Box'
import FacebookIcon from '@mui/icons-material/Facebook'
import Grid from '@mui/material/Grid'
import InstagramIcon from '@mui/icons-material/Instagram'
import Link from './mui/Link'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Typography from '@mui/material/Typography'
import XIcon from '@mui/icons-material/X'

import footerSignatureImagePath from 'assets/bruce-bowser-signature.webp'

function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <Grid
      item
      container
      rowGap={2}
      justifyContent="center"
      pt={6}
      bgcolor="secondary.light"
      color="#fff"
      style={{
        backgroundColor: 'secondary.light',
        backgroundImage: `url(${footerSignatureImagePath})`,
        backgroundPosition: 'bottom left',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid item>
        <Typography
          variant="h4"
          sx={{
            fontFamily:
              'Saira Extra Condensed, Helvetica Neue, Helvetica, Arial, sans-serif',
            letterSpacing: '2.19px',
          }}
        >
          Follow me on
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Box display="flex">
          <Link
            to="https://www.linkedin.com/in/brucebowser"
            target="_blank"
            sx={{
              border: '1px solid #606367',
              padding: '8px 8px 6px 8px',
              margin: '8px',
            }}
          >
            <LinkedInIcon color="primary" fontSize="large" />
          </Link>
          <Link
            to="https://twitter.com/bruce_bowser"
            target="_blank"
            sx={{
              border: '1px solid #606367',
              padding: '8px 8px 6px 8px',
              margin: '8px',
            }}
          >
            <XIcon color="primary" fontSize="large" />
          </Link>
          <Link
            to="https://www.facebook.com/1BruceBowser"
            target="_blank"
            sx={{
              border: '1px solid #606367',
              padding: '8px 8px 6px 8px',
              margin: '8px',
            }}
          >
            <FacebookIcon color="primary" fontSize="large" />
          </Link>
          <Link
            to="https://www.instagram.com/brucebowser/"
            target="_blank"
            sx={{
              border: '1px solid #606367',
              padding: '8px 8px 6px 8px',
              margin: '8px',
            }}
          >
            <InstagramIcon color="primary" fontSize="large" />
          </Link>
        </Box>
      </Grid>

      <Grid item container justifyContent={{ xs: 'center', sm: 'end' }} m={4}>
        <Typography
          color="#747272"
          textTransform="uppercase"
          textAlign="center"
        >
          {`© copyright bruce bowser ${currentYear}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer
