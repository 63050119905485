import { useMemo } from 'react'
import { Carousel } from 'react-responsive-carousel'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import ImageListItem from '@mui/material/ImageListItem'

import portraitImagePath from 'assets/portrait-body-bruce-bowser.webp'
import amjConferenceImagePath from 'assets/amj-conference-bruce.webp'
import bowserFamilyImagePath from 'assets/bowser-family-portrait.webp'
import bowserFamily2ImagePath from 'assets/bowser-family-stmarys.webp'
import boxingBruceImagePath from 'assets/boxing-bruce.webp'
import hcolBruceImagePath from 'assets/hcol-bruce.webp'
import planeBruceImagePath from 'assets/plane-bruce.webp'
import businessSchoolBruceImagePath from 'assets/roger-business-school-bruce.webp'

import theme from 'styles/theme'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Link from 'components/mui/Link'

function Biography() {
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'))

  const itemData = useMemo(
    () => [
      {
        image: hcolBruceImagePath,
        title: 'Bruce Bowser as Honorary Colonel',
        cols: 3,
        rows: 7,
      },
      {
        image: amjConferenceImagePath,
        title: 'Bruce at AMJ conference',
        cols: 3,
        rows: 3,
      },
      {
        image: planeBruceImagePath,
        title: 'Bruce Bowser standing on a plane',
        cols: 3,
        rows: 4,
      },
      {
        image: businessSchoolBruceImagePath,
        title: 'Bruce Bowser at Roger School of Business ',
        cols: 3,
        rows: 2,
      },
      {
        image: bowserFamilyImagePath,
        title: 'Father of two daughters',
        cols: 3,
        rows: 5,
      },
      {
        image: bowserFamily2ImagePath,
        title: 'Bowser family at St. Marys',
        cols: 3,
        rows: 4,
      },
      {
        image: boxingBruceImagePath,
        title: `Fundraising event for Men's health`,
        cols: 3,
        rows: 3,
      },
    ],
    []
  )

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    const responsiveSize = isMediumDown ? 80 : size
    const responsiveRows = isSmallDown ? 2 : rows
    const responsiveCols = isSmallDown ? 3 : cols
    return {
      src: `${image}?w=${responsiveSize * responsiveCols}&h=${
        responsiveSize * responsiveRows
      }&fit=crop&auto=format`,
      srcSet: `${image}?w=${responsiveSize * responsiveCols}&h=${
        responsiveSize * responsiveRows
      }&fit=crop&auto=format&dpr=2 2x`,
    }
  }

  return (
    <Grid
      id="biography"
      container
      sx={{
        width: '100%',
        backgroundColor: '#fff',
      }}
    >
      <Grid item xs={12} p={4}>
        <Typography
          variant="h2"
          color="primary"
          sx={{
            fontFamily:
              'Saira Extra Condensed, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '2.5rem',
            fontWeight: 400,
            letterSpacing: '7.3px',
            textTransform: 'uppercase',
          }}
        >
          Biography
        </Typography>
      </Grid>
      <Grid
        item
        container
        display="grid"
        sx={{
          position: isMediumDown ? 'relative' : 'none',
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={4}
          order={{ xs: 1, md: 2, lg: 2 }}
          position={{ xs: 'relative', md: 'absolute' }}
          right={{ xs: 'none', md: 0 }}
        >
          <img
            src={portraitImagePath}
            alt="Bruce Bowser portrait"
            width="480"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={8}
          lg={7}
          px={2}
          py={4}
          m={{ xs: 0, md: 3 }}
          order={{ xs: 2, md: 1 }}
          sx={{
            backgroundColor: { xs: 'primary.main', md: '#fff' },
            borderLeft: { xs: 'none', md: '5px solid #25A6BD' },
            color: { xs: '#fff', md: '#000' },
            zIndex: 99,
          }}
        >
          <Typography variant="h3">
            Bruce Bowser is a highly respected Canadian business leader and
            Chairman of AMJ Campbell Van Lines.
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={7}
          lg={8}
          p={4}
          order={{ xs: 3, md: 2 }}
          sx={{ zIndex: 99 }}
        >
          <Typography variant="body1">
            Previously a banker from Halifax, Nova Scotia, Bruce decided to
            follow his entrepreneurial dreams and joined AMJ over two decades
            ago. His keen business acumen saw him rise through management where
            he quickly reached his current position as Chairman. Bruce also
            served as President and CEO for AMJ Campbell's parent company,
            publicly-traded CamVec Corp., and led a management bid to take the
            company private in 2002.
            <br />
            <br /> As PRESIDENT, Bruce oversaw eight separate business divisions
            in 50 locations across Canada, running over 75 operations, with more
            than 2,800 staff. Under his leadership, AMJ has GREW to an
            internationally respected brand, with over $200 million in sales.
            <br />
            <br /> A well-known business authority, Bruce is frequently sought
            out by media to provide his innovative and entrepreneurial
            point-of-view. He has appeared on CBC's “Back to the Floor" and CNN
            Balance with Dr. Gupta. Bruce has also been featured in notable
            publications such as the National Post, The Globe and Mail, and
            Business Edge.
            <br />
            <br />
            Bruce is currently working with his daughter, Dr. Robyn Bowser to
            open a chain of Vet clinics across Canada -{' '}
            <Link to="https://www.aprilvets.com" target="_blank">
              www.aprilvets.com
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container xs={12} md={6} p={5}>
          <Grid
            item
            xs={12}
            md={10}
            pl={isMediumDown ? 2 : 5}
            sx={{ borderLeft: '5px solid #25A6BD' }}
          >
            <Typography variant="h2">
              Live the life you'd want to live again.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} position="relative">
          <Box
            sx={{
              backgroundColor: '#000',
              color: '#fff',
              padding: isMediumDown ? 4 : 10,
              position: isMediumDown ? 'undefined' : 'absolute',
              top: isMediumDown ? 'undefined' : -30,
            }}
          >
            <Typography>
              Bruce exemplifies the mantra “live the life you'd want to live
              again.” He lives this philosophy in both his professional and
              personal life. Bruce is committed to foster a positive work-life
              balance for his team at AMJ Campbell, and outside the office, he
              tirelessly advocates this belief to the broader business
              community.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        px={isMediumDown ? 2 : 5}
        py={isMediumDown ? 4 : 12}
        sx={{
          backgroundColor: 'primary.main',
          color: '#fff',
        }}
        direction={{ xs: 'row', md: 'row-reverse' }}
        justifyContent="space-between"
        alignItems="start"
      >
        <Grid item xs={12} md={7} p={{ xs: 0, md: 4 }}>
          <Typography variant="h2">
            A father of two daughters, Bruce stands for equality; proudly
            advocating for women's rights.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} py={{ xs: 2, md: 0 }}>
          <Typography>
            Bruce values the importance of giving back and has served on several
            charity boards including the National Ballet School of Canada,
            Canada Company, Teen Ranch Foundation, Credit Valley Hospital
            Foundation and the Halifax/Dartmouth YMCA. Bruce has also served on
            several corporate boards including FYidoctors, The National Club,
            and Atlas Canada. An avid pilot, Bowser lends his time to supporting
            the Royal Canadian Air Force and our Canadian Armed Forces. Bruce
            was appointed to Honorary Colonel (RCAF) in November 2017. He
            recently Chaired the 100th Anniversary of the Royal Winter fair and
            currently serves on their Board of Directors.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        {isSmallDown ? (
          <Grid item xs={12}>
            <Carousel showArrows={true} showThumbs={false} dynamicHeight>
              {itemData.map((item) => (
                <div key={item.title}>
                  <img src={item.image} alt={item.title} width="auto" />
                  <p className="legend">{item.title}</p>
                </div>
              ))}
            </Carousel>
          </Grid>
        ) : (
          <ImageList
            sx={{ width: 1600, height: 730 }}
            variant="quilted"
            cols={12}
            rowHeight={100}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.image}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  {...srcset(item.image, 100, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar title={item.title} />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Grid>
    </Grid>
  )
}

export default Biography
