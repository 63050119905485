import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'

import { GenericObject } from 'common/types'

export type ButtonProps = MuiButtonProps & {
  mode?: 'back' | 'forward'
  iconColor?: string
  isLightHover?: boolean
  isLightButton?: boolean
  to?: string | GenericObject
}

export const baseButtonStyling = {
  py: 1,
  px: 2,
  minWidth: 72,
  borderRadius: '6px',
}

const Button = forwardRef(
  (
    {
      variant = 'contained',
      mode,
      color = 'primary',
      iconColor = 'inherit',
      isLightHover = false,
      isLightButton = false,
      to,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const config: any = {
      target: props.href && '_blank',
    }

    if (to) {
      config.component = Link
      config.to = to
    }

    const lightButtonStyles = {
      backgroundColor: 'primary.lightest',
      color: 'primary.main',
      '&:hover': {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
      },
    }

    return (
      <MuiButton
        {...config}
        disableElevation
        variant={variant}
        color={color}
        sx={{
          ...baseButtonStyling,
          '&:hover': {
            backgroundColor: isLightHover ? `${color}.light` : color,
          },
          ...(isLightButton && lightButtonStyles),
          ...props.sx,
        }}
        ref={ref}
      >
        {props.children}
      </MuiButton>
    )
  }
)

export default Button
